











































declare const $: any;
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { getRouter } from "../../router";

@Component({ name: "SKREditor" })
export default class SKREditor extends Vue {
  @Prop() item!: any;
  routerName: any = "";

  constructor() {
    super();
    this.routerName = getRouter().currentRoute.name;
  }

  deleteItem() {
    $("#item_" + this.item[4]).remove();
  }

  getValue(it: any) {
    if (it[2] === "DERIVED") {
      return it[3];
    } else {
      let str = "";
      if (it[3]) {
        it[3].forEach((i: any, index: number) => {
          if (i[0] === i[1]) {
            str += i[0];
          } else {
            str += i[0] + "-" + i[1];
          }
          if (index !== it[3].length - 1) {
            str += ",";
          }
        });
      }
      return str;
    }
  }
}
