



























































































































































































































































































import TopBar from "@/components/common/TopBar.vue";
import SKREditor from "@/components/common/SKREditor.vue";
import Vue from "vue";
import store from "../../store";
import Component from "vue-class-component";
import { getRouter } from "../../router";
import { Watch } from "vue-property-decorator";
import { callSkrEditor } from "../../helper/utils";
import api from "../../api";
declare const $: any;

@Component({ components: { TopBar, SKREditor } })
export default class SKR extends Vue {
  prevId = 0;
  editorTitle = "SKR Struktur Template Editor";
  routerName: any = "";
  templateName = "";
  nameIssue = false;
  messageStatus = 0;
  selectedYear = "";
  client: any = "";
  // agency = ''
  agencies: any[] = [];
  clients: any[] = [];
  loadedAgencies: any[] = [];
  selectYears: any[] = [];
  dropdownSearch: string = "";
  dropdownClientSearch: string = "";
  timer: any = null;
  cachedAgencies: any[] = [];
  cachedClients: any[] = [];

  constructor() {
    super();
    this.getAgencies();
  }

  preventDefault(e: any) {
    e.stopPropagation();
  }

  topBarSearch() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.timer = setTimeout(() => {
      this.cachedAgencies = this.agencies.filter((itm: any) => {
        if (
          this.dropdownSearch == "" ||
          itm.toLowerCase().includes(this.dropdownSearch.toLowerCase())
        ) {
          return itm;
        }
      });
    }, 300);
  }
  clearOnEsc() {
    this.dropdownSearch = "";
    this.dropdownClientSearch = "";
    this.cachedAgencies = this.agencies;
    this.cachedClients = this.clients;
    //A way to handle Jquery component inside Vuejs
    const _bodySelector = document.querySelector("body");
    if (_bodySelector) {
      _bodySelector.click();
    }
  }
  topBarClientSearch() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.timer = setTimeout(() => {
      this.cachedClients = this.clients.filter((itm: any) => {
        if (
          this.dropdownClientSearch == "" ||
          itm.name
            .toLowerCase()
            .includes(this.dropdownClientSearch.toLowerCase()) ||
          itm.mnumber
            .toLowerCase()
            .includes(this.dropdownClientSearch.toLowerCase())
        ) {
          return itm;
        }
      });
    }, 300);
  }

  mounted() {
    this.initSortable();
    this.onTopBarDataChange();
    this.routerName = getRouter().currentRoute.name;
    if (this.routerName === "skr_mandant") {
      this.editorTitle = "SKR Auswahl";
    }
    //Support Bootstrap inside vuejs
    document.body.addEventListener("keyup", (e) => {
      if (e.keyCode === 27) {
        this.clearOnEsc();
      }
    });
  }

  get templateData() {
    return store.getters.skrTemplateData;
  }

  get skrTemplates() {
    return store.getters.skrTemplates || [];
  }

  get topBarData() {
    return {
      client: store.getters.selectedClient,
    };
  }

  get biAgency() {
    return store.getters.selectedBiAgency;
  }
  // get client() {
  //   return store.getters.selectedClient
  // }
  get agency() {
    return store.getters.selectedAgency;
  }

  getAgencies() {
    api.getConfigClientMandators().then((res) => {
      this.loadedAgencies = res.data;
      let getAgencies = Object.keys(res.data);
      getAgencies.sort();
      this.agencies = getAgencies;
      this.cachedAgencies = getAgencies;

      if (this.agency == null) {
        store.dispatch("updateSelectedAgency", getAgencies[0]);
      }

      // if(this.agency != '' && this.loadedAgencies.length){
      let selectedAgency = this.agency;
      this.clients = this.loadedAgencies[selectedAgency];
      this.cachedClients = this.loadedAgencies[selectedAgency];
      this.client = this.clients[0] || "";
      // }
    });
  }

  @Watch("agency")
  onAgencyChange() {
    let selectedAgency = this.agency;
    this.clients = this.loadedAgencies[selectedAgency];
    this.cachedClients = this.loadedAgencies[selectedAgency];
    this.client = this.clients[0] || "";
  }

  @Watch("client")
  onClientChange() {
    this.getSkrYears();
    this.pushStateHistory();
  }

  getSkrYears() {
    this.selectedYear = "";
    this.templateName = "";
    api
      .getSkrmandyears(this.agency, this.client.mnumber)
      .then((res) => {
        store.dispatch("updateLoading", store.state.loading - 1);
        this.selectYears = Object.keys(res.data);
      })
      .catch((err) => {
        store.dispatch("updateLoading", store.state.loading - 1);
      });
  }

  setMandator(client: any) {
    localStorage.setItem("client", JSON.stringify(client));
    this.client = client;
    store.dispatch("updateSelectedClient", client);
  }

  setAgency(agency: any) {
    localStorage.setItem("agency", JSON.stringify(agency));
    store.dispatch("updateSelectedAgency", agency);
  }

  @Watch("topBarData")
  onTopBarDataChange() {
    if (this.routerName === "skr_mandant") {
      this.selectedYear = "";
      this.templateName = "";
    }
    if (!store.getters.isSkrCalled) {
      callSkrEditor();
    }
  }

  @Watch("biAgency")
  onBiAgencyChange() {
    this.pushStateHistory();
  }

  initSortable() {
    ($("ol.sortable") as any).nestedSortable({
      forcePlaceholderSize: true,
      handle: "div",
      helper: "clone",
      items: "li",
      opacity: 0.6,
      placeholder: "placeholder",
      revert: 250,
      tabSize: 25,
      tolerance: "pointer",
      toleranceElement: "> div",
      maxLevels: 1,
      isTree: true,
      expandOnHover: 700,
      startCollapsed: false,
      change: function() {},
    });
  }

  addItem() {
    this.messageStatus = 0;
    this.prevId += 1;
    this.templateData.push(["", "", "", "", this.prevId]);
  }

  getId(items: any) {
    if (!items) {
      return;
    }
    for (var i = 0; i < items.length; i++) {
      if (this.prevId < parseInt(items[i][4])) {
        this.prevId = parseInt(items[i][4]);
      }
    }
  }

  pushStateHistory() {
    // const selectedStb = (this.agency != null) ? this.agency : stb
    let path =
      "/einstellungen/admin/skr_mandant" +
      "/" +
      encodeURIComponent(this.biAgency) +
      "/" +
      encodeURIComponent(this.client.mnumber);
    if (this.$route.path !== path) {
      this.$router.replace(path);
    }
  }

  save() {
    if (!this.templateName) {
      this.nameIssue = true;
      return;
    }
    this.nameIssue = false;
    if (this.routerName === "skr_editor_uknow") {
      const json = $("ol.sortable").nestedSortable("toHierarchy", {
        startDepthCount: 0,
      });
      let convertedVal: any[] = [];
      json.forEach((d: any) => {
        if (Object.keys(d).length > 4) {
          convertedVal.push([d[0], d[1], d[2], d[3], d[4]]);
        }
      });
      store.dispatch(
        "updateLoading",
        store.state.loading >= 0 ? store.state.loading + 1 : 1
      );
      api
        .saveUknowSkr(this.templateName, convertedVal)
        .then((res) => {
          this.messageStatus = 1;
          store.dispatch("updateLoading", store.state.loading - 1);
        })
        .catch((err) => {
          this.messageStatus = -1;
          store.dispatch("updateLoading", store.state.loading - 1);
        });
    } else if (this.routerName === "skr_mandant") {
      if (!this.selectedYear) {
        return;
      }
      store.dispatch(
        "updateLoading",
        store.state.loading >= 0 ? store.state.loading + 1 : 1
      );

      let topBarDataClient = [this.agency, this.client.mnumber];
      api
        .saveMandantSkr(topBarDataClient, this.selectedYear, this.templateName)
        .then((res) => {
          this.messageStatus = 1;
          store.dispatch("updateLoading", store.state.loading - 1);
        })
        .catch((err) => {
          this.messageStatus = -1;
          store.dispatch("updateLoading", store.state.loading - 1);
        });
    }
  }

  loadTemplate(e: any) {
    this.messageStatus = 0;
    store.dispatch(
      "updateLoading",
      store.state.loading >= 0 ? store.state.loading + 1 : 1
    );
    if (this.routerName === "skr_editor_uknow") {
      this.templateName = e.target.value;
      this.nameIssue = false;
      api
        .getUknowSkrTemplate(this.templateName)
        .then((res) => {
          this.getId(res.data);
          store.dispatch("updateSkrTemplateData", res.data);
          store.dispatch("updateLoading", store.state.loading - 1);
        })
        .catch((err) => {
          store.dispatch("updateSkrTemplateData", []);
          store.dispatch("updateLoading", store.state.loading - 1);
        });
    } else if (this.routerName === "skr_mandant") {
      let agencyClient = [this.agency, this.client.mnumber];
      api
        .getMandantSkr(agencyClient, this.selectedYear)
        .then((res) => {
          this.templateName = res.data;
          store.dispatch("updateLoading", store.state.loading - 1);
        })
        .catch((err) => {
          store.dispatch("updateLoading", store.state.loading - 1);
        });
    }
  }

  deleteTemplate() {
    if (this.routerName === "skr_editor_uknow") {
      if (!this.templateName) {
        return;
      }
      store.dispatch(
        "updateLoading",
        store.state.loading >= 0 ? store.state.loading + 1 : 1
      );
      api
        .deleteUknowSkr(this.templateName)
        .then((res) => {
          callSkrEditor();
          this.prevId = 0;
          this.templateName = "";
          store.dispatch("updateSkrTemplateData", []);
          store.dispatch("updateLoading", store.state.loading - 1);
        })
        .catch((err) => {
          store.dispatch("updateSkrTemplateData", []);
          store.dispatch("updateLoading", store.state.loading - 1);
        });
    } else {
      if (!this.selectedYear) {
        return;
      }
      store.dispatch(
        "updateLoading",
        store.state.loading >= 0 ? store.state.loading + 1 : 1
      );
      api
        .deleteMandantSkr(this.topBarData.client, this.selectedYear)
        .then((res) => {
          this.templateName = "";
          this.selectedYear = "";
          store.dispatch("updateLoading", store.state.loading - 1);
        })
        .catch((err) => {
          store.dispatch("updateLoading", store.state.loading - 1);
        });
    }
  }
}
